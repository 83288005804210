(function() {
    'use strict';

    var app = angular
        .module('talo', [
            'ngAnimate',
            'ngCookies',
            'ngTouch',
            'ngSanitize',
            'ngResource',
            'ui.router',
            'ui.bootstrap',
            'nya.bootstrap.select',
            'ngDialog',
            'cgBusy',
            'angular-google-analytics',

            'talo.core',
            'talo.contact',
            'talo.data',
            'talo.common',
            'talo.home'
        ]);

    app.config(['AnalyticsProvider', function (AnalyticsProvider) {
        AnalyticsProvider.setAccount(Config.GATracker);
    }]);

    app.run(['$rootScope', 'Analytics', function ( $rootScope, Analytics) {
        $rootScope.title = Config.PageTitle;
    }]);
})();