(function () {
    'use strict';

    angular.module('talo.common').filter('area', function () {
        return function (number) {
            if(number === null || number<=0 || number === undefined){
                return '-';
            }
            return number.toFixed(2).replace(".",",");
        };
    });

})();