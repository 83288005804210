$(document).ready(function () {


    function checkProportion(){
        if(window.innerWidth < window.innerHeight){
            $('body').addClass('vertical');
        }else {
            $('body').removeClass('vertical');
        }
    }

    checkProportion();
    $(window).on('resize', checkProportion);


    function setContentHeight() {
        if (window.innerWidth < 992) {
            $('.content').addClass('collapsed');
        } else {
            $('.content').removeClass('collapsed');
        }
    }
    setContentHeight();

    $(window).on('resize', setContentHeight);

    $('.gradientMask a').each(function () {
        var item = $(this);
        item.on('click', function (e) {
            e.preventDefault();
            item.parents('.content').removeClass('collapsed');
        });
    });

    var searchIsVisible = false;
    function switchFixSearch() {
        if (!searchIsVisible) {
            var scrollPos = $(window).scrollTop();
            if (scrollPos > 10) {

                $('#searchFixed').css('bottom', '-' + ($('#searchFixed').height() + 10) + 'px');
                $('#searchInfo').css('bottom', 0);
            } else {
                $('#searchFixed').css('bottom', 0);
                $('#searchInfo').css('bottom', '-90px');
            }
        } else {
            $('#searchInfo').css('bottom', '-90px');
        }
    }
    $(window).on('scroll', switchFixSearch);
    switchFixSearch();

    var searchSection = document.getElementById("searchSection");
    new Waypoint({
        element: searchSection,
        handler: function (direction) {
            if (direction == 'down') {
                searchIsVisible = true;
            } else {
                searchIsVisible = false;
            }

        },
        offset: '100%'

    });

    /*$('#closeBtn').on('click', function (e) {
        e.preventDefault();
        $('#flatModal').hide();
    });*/

    $('#showMenu').on('click', function (e) {
        e.preventDefault();
        $('#extendMenu').css('top', 0);
    });

    $(document).click(function (event) {
        if (!$(event.target).closest('#extendMenu').length) {
            if ($('#extendMenu').css('top') == 0 || $('#extendMenu').css('top') == '0px') {
                $('#extendMenu').css('top', '-250px');
            }
        }
    });
    
    /*$('.scrollTo').each(function () {

        var element = $(this);
        element.on('click', function (e, ui) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: $("#"+element.data('to')).offset().top
            }, 1000);
            
        });
    });*/
    
    
    /*$('#showSearchModal').on('click', function(e){
        e.preventDefault();
        $('#searchModal').show();
    });
    
    $('.closeSearchModal').on('click', function (e) {
        e.preventDefault();
        $('#searchModal').hide();
    });*/
});