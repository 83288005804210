(function () {
    'use strict';

    angular.module('talo.common').filter('localStatus', function () {
        return function (status) {
            switch(status){
                case 0:
                    return 'dostępne';
                case 1:
                    return 'wstępna rezerwacja';
                case 2:
                    return 'sprzedane';
                case 3:
                    return 'umowa rezerwacyjna';
                default:
                    return '-';
            }
        };
    });

})();