(function(){
    'use strict';

    var app = angular.module('talo.home');
    app.controller('flatedetailsController', flatdetailsController);

    flatdetailsController.$inject = ['$scope', 'flats','$q','ngDialog', '$rootScope'];

    function flatdetailsController($scope, flats, $q, ngDialog, $rootScope) {
        var vm = this;
        vm.flatId = $scope.flatId;
        vm.flat = undefined;
        vm.ready = init();
        vm.flatSheetAvailable = false;
        vm.flatSheetUrl = undefined;

        vm.flatPlanAvailable = false;
        vm.flatPlan = undefined;
        vm.flatPlanName = '';

        vm.floorPlanAvailable = false;
        vm.floorPlan = undefined;
        vm.floorPlanName = '';
        vm.askQuestion = askQuestion;


        function init(){
            var promises = [];

            promises.push(getFlatDetails());

            return $q.all(promises);
        }

        function getFlatDetails(){
            return flats.details({flatId: vm.flatId},
                function(result){
                    vm.flat = result;
                    preparePresentation();
                }
            ).$promise;
        }

        function preparePresentation(){
            if(vm.flat.localSheet!=null){
                vm.flatSheetUrl = vm.flat.localSheet;
                vm.flatSheetAvailable = true;
            }

            for(var i=0;i<vm.flat.photos.length;i++){
                var photo = vm.flat.photos[i];

                if(photo.kind == 1){
                    vm.flatPlan = photo.url;
                    vm.flatPlanName = photo.name;
                    vm.flatPlanAvailable = true;
                }

                if(photo.kind == 2){
                    vm.floorPlan = photo.url;
                    vm.floorPlanName = photo.name;
                    vm.floorPlanAvailable = true;
                }
            }
        }

        function askQuestion(){
            $rootScope.$emit('askQuestion', vm.flat.number);
            ngDialog.close();
        }
    }
})();