(function () {
    'use strict';

    angular
        .module('talo.home')
        .config(configure);

    configure.$inject = ['$stateProvider', '$urlRouterProvider'];

    function configure($stateProvider, $urlRouterProvider) {
        configureRoutes($stateProvider, $urlRouterProvider);
    }

    var configureRoutes = function ($stateProvider, $urlRouterProvider) {

        $urlRouterProvider.when('', '/');

        $stateProvider
            .state('home', {
                url: '/',
                templateUrl: '/components/home/home.html',
                controller: 'homeController',
                controllerAs: 'vm'
            });
    };
})();