(function(){
    'use strict';

    var app = angular.module('talo.common');

    app.directive('scrollTo', function() {
        return {
            restrict: 'A',
            link: function(scope, $elm, attrs) {
                var idToScroll = attrs.to;
                $elm.on('click', function() {
                    var $target;
                    if (idToScroll) {
                        $target = $('#'+idToScroll);
                    } else {
                        $target = $elm;
                    }
                    $('html, body').animate({
                        scrollTop: $target.offset().top
                    }, 1000);
                });
            }
        };
    });
})();