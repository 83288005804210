(function () {
    'use strict';

    angular
        .module('talo.data')
        .factory('flats', ['$resource', flatsApi])
        .factory('contact', ['$resource', contactApi]);

    function flatsApi($resource) {
        var baseUri = Config.API + 'investments/'+Config.InvestmentId+'/stages/'+Config.StageId+'/flats';
        return $resource(baseUri, {}, {
            details: {
                url: baseUri+'/:flatId',
                method: 'get',
                params: {
                    flatId: '@flatId'
                }
            }
        });
    }

    function contactApi($resource) {
        var baseUri = Config.API + 'contact/client';
        return $resource(baseUri, {}, {
            send: {
                url: baseUri,
                method: 'post'
            }
        });
    }

})();