(function() {
    'use strict';

    angular.module('talo.home').controller('homeController', homeController);

    homeController.$inject = ['$templateCache','$q','flats','ngDialog', '$scope'];

    function homeController($templateCache, $q, flats, ngDialog, $scope){
        var vm = this;
        vm.ready = init();

        vm.parameters = {
            rooms: {
                number: '-',
                selected: -1,
                options: ['-','1','2','3','4','5']
            },
            area:{
                min: 28,
                max: 59,
                range: [28,59],
                selected: {
                    min: 28,
                    max: 59
                }
            },
            floors:{
                min: 0,
                max: 5,
                selected: {
                    min: 0,
                    max: 5
                },
                ticks: [0,1,2,3,4,5],
                range: [0, 5],
                selectedMin: 0

            }
        };

        vm.flats = [];

        vm.sortType = 'symbol';
        vm.sortReverse = false;

        vm.changeSorting = function(column, defaultReverse){
            if(vm.sortType == column){
                vm.sortReverse = !vm.sortReverse;
                return;
            }

            vm.sortType = column;
            vm.sortReverse = defaultReverse;
        };

        function init() {
            var promises = [];

            promises.push(getFlats());

            return $q.all(promises);
        }

        function getFlats(){
            return flats.query({},function(data){
                vm.flats = data;

                if(vm.flats.length>0){
                    adjustSearchPanel();
                }
            }).$promise;
        }

        function adjustSearchPanel(){
            var minRooms = 100;
            var maxRooms = -1;
            var minFloor = 100;
            var maxFloor = -10;
            var maxArea = 0.0;
            var minArea = 1000.0;

            for(var i=0;i<vm.flats.length;i++){
                var flat = vm.flats[i];
                if(maxRooms<flat.roomsCount){
                    maxRooms = flat.roomsCount;
                }
                if(minRooms>flat.roomsCount){
                    minRooms = flat.roomsCount;
                }
                if(maxFloor<flat.floor){
                    maxFloor = flat.floor;
                }
                if(minFloor>flat.floor){
                    minFloor = flat.floor;
                }
                if(maxArea<flat.flatArea){
                    maxArea = flat.flatArea;
                }
                if(minArea>flat.flatArea){
                    minArea = flat.flatArea;
                }
            }

            vm.parameters.floors.min = minFloor;
            vm.parameters.floors.max = maxFloor;
            vm.parameters.floors.range = [minFloor, maxFloor];
            var ticks = [];
            for(i=minFloor;i<=maxFloor;i++) {
                ticks.push(i);
            }
            vm.parameters.floors.ticks = ticks;
            vm.parameters.floors.selected.min = minFloor;
            vm.parameters.floors.selected.max = maxFloor;



            var rooms = [];
            rooms.push('-');
            for(i=minRooms;i<=maxRooms;i++){
                rooms.push(''+i);
            }
            vm.parameters.rooms.options = rooms;
            vm.parameters.rooms.number = '-';
            vm.parameters.rooms.selected = -1;

            minArea = Math.floor(minArea);
            maxArea = Math.ceil(maxArea);
            vm.parameters.area.min = minArea;
            vm.parameters.area.max = maxArea;
            vm.parameters.area.range = [minArea, maxArea];
            vm.parameters.area.selected.min = minArea;
            vm.parameters.area.selected.max = maxArea;
        }

        vm.filterFlats = function(flat){
            if(vm.parameters.rooms.selected != -1 && flat.roomsCount != vm.parameters.rooms.selected) {
                return false;
            }

            if(flat.floor < vm.parameters.floors.selected.min || flat.floor > vm.parameters.floors.selected.max){
                return false;
            }

            if(flat.flatArea < vm.parameters.area.selected.min || flat.flatArea > vm.parameters.area.selected.max){
                return false;
            }

            return true;
        };

        vm.search = function(){
            if(vm.parameters.rooms.number === '-'){
                vm.parameters.rooms.selected = -1;
            } else {
                vm.parameters.rooms.selected = parseInt(vm.parameters.rooms.number);
            }
            vm.parameters.area.selected.min = vm.parameters.area.range[0];
            vm.parameters.area.selected.max = vm.parameters.area.range[1];
            vm.parameters.floors.selected.min = vm.parameters.floors.range[0];
            vm.parameters.floors.selected.max = vm.parameters.floors.range[1];
        };

        vm.showDetails = function(flatId){
            $scope.flatId = flatId;

            ngDialog.open({
                template: '/components/flatdetails/flatdetails.html',
                className: 'modalCustom',// animated fadeIn',
                showClose: false,
                controller: 'flatedetailsController',
                controllerAs: 'vm',
                scope: $scope
            });
        };
    }
})();

