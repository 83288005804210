(function(){
    'use strict';

    var app = angular.module('talo.contact');

    app.controller('contactController', contactController);

    contactController.$inject = ['contact', '$rootScope'];

    function contactController(contact, $rootScope){
        var vm = this;

        vm.email = '';
        vm.name = '';
        vm.contact = '';
        vm.messageWasSent = false;

        vm.ready = null;

        $rootScope.$on('askQuestion', function(event, localnumber){
            setInitialMessage(localnumber);
        });

        function setInitialMessage(localNumber){
            vm.contact = 'Dzień dobry,\n' +
                '\n' +
                'Proszę o informację na temat lokalu '+localNumber;
        }

        vm.submit = function() {
            vm.ready = contact.send({investmentId: Config.InvestmentId, email: vm.email, name: vm.name, content: vm.contact}).$promise;
            vm.contact = '';
            vm.email = '';
            vm.name = '';
            vm.messageWasSent = true;
        };
    }
})();
